import _ from "lodash";

import { multipart_msg_t } from "@skydio/lcm/types/skybus_tunnel/multipart_msg_t";

import ChannelBuffer, { SendDataCallback, ReceiveMsgCallback } from "./channel_buffer";

import {
  TransmitStats,
  TunnelStats,
  ReceiveStats,
  initialTransmitStats,
  initialReceiveStats,
} from "./stats";
import { PublishOptions } from "../types";
import { logger } from "../logger";

export default class Packetizer {
  private sendData: SendDataCallback;
  private receiveMsg: ReceiveMsgCallback;
  private channelBufferMap: Record<string, ChannelBuffer>;
  private maxChunkSize: number;
  private maxEventBufferLength: number;
  private transmitStats: TunnelStats["sentByChannel"];

  constructor(
    sendDataCallback: SendDataCallback,
    receiveMsgCallback: ReceiveMsgCallback,
    maxChunkSize: number,
    maxEventBufferLength = 5
  ) {
    this.sendData = sendDataCallback;
    this.receiveMsg = receiveMsgCallback;
    this.channelBufferMap = {};
    this.maxChunkSize = maxChunkSize;
    this.maxEventBufferLength = maxEventBufferLength;
    this.transmitStats = {};
  }

  public transmitEvent(channel: string, data: Uint8Array, options?: PublishOptions) {
    const msg = new multipart_msg_t({ channel, chunk_size: 0 });
    // account for marshaling overhead
    const maxChunkSize = this.maxChunkSize - msg._get_encoded_size();
    const chunkCount = Math.max(1, Math.ceil(data.length / maxChunkSize));

    // multipart_msg_t.chunk_count uses an 8-bit int
    if (chunkCount > 127) {
      logger.error("Event too large: channel=", channel);
      logger.log(`Dropping ${data.length} byte event on ${channel}`);
      return;
    }

    if (!(channel in this.transmitStats)) {
      this.transmitStats[channel] = { ...initialTransmitStats };
    }
    const channelStats = this.transmitStats[channel]!;
    const eventId = channelStats.events;

    channelStats.events++;
    channelStats.bytes += data.length;

    _.times(chunkCount, i => {
      const startOffset = i * maxChunkSize;
      const endOffset = Math.min(data.length, (i + 1) * maxChunkSize);

      msg.id = eventId;
      msg.total_size = data.length;
      msg.chunk_index = i;
      msg.chunk_count = chunkCount;
      msg.chunk_size = endOffset - startOffset;
      // pretty much the closest we can do to a memcpy in JavaScript
      const buffer = new ArrayBuffer(msg.chunk_size);
      const chunkData = new Uint8Array(buffer);
      chunkData.set(new Uint8Array(data.buffer, startOffset, msg.chunk_size));
      msg.chunk_data = chunkData;

      this.sendData(channel, new Uint8Array(msg.encode()), options);
    });
  }

  public handleChunkData(data: Uint8Array) {
    const wrappedMsg = new multipart_msg_t().decode(new Uint8Array(data));
    const { channel } = wrappedMsg;
    if (!(channel in this.channelBufferMap)) {
      this.channelBufferMap[channel] = new ChannelBuffer(
        channel,
        this.receiveMsg,
        this.maxEventBufferLength
      );
    }
    this.channelBufferMap[channel]!.pushChunk(wrappedMsg);
  }

  public tick() {
    _.forEach(this.channelBufferMap, buffer => {
      buffer.tick();
    });
  }

  public getStats(): TunnelStats {
    // @ts-ignore
    const [sentByChannel, totalSent] = _.reduce(
      this.transmitStats,
      ([byChannel, total], stats, channel) => [
        { ...byChannel, [channel]: stats },
        _.mapValues(total, (val, key: keyof TransmitStats) => val + stats[key]),
      ],
      [{} as TunnelStats["sentByChannel"], { ...initialTransmitStats }]
    );

    // @ts-ignore
    const [receivedByChannel, totalReceived] = _.reduce(
      this.channelBufferMap,
      ([byChannel, total], buffer, channel) => {
        const stats = buffer.getStats();
        return [
          { ...byChannel, [channel]: buffer.getStats() },
          _.mapValues(total, (val, key: keyof ReceiveStats) => {
            if (key.endsWith("Time")) {
              return Math.max(val, stats[key]);
            }
            return val + stats[key];
          }),
        ];
      },
      [{} as TunnelStats["receivedByChannel"], { ...initialReceiveStats }]
    );

    return {
      sentByChannel,
      receivedByChannel,
      totalSent,
      totalReceived,
    };
  }
}

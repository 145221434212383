// source: pbtypes/gen/phone/advanced_setting.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.phone.AdvancedSetting', null, global);
goog.exportSymbol('proto.phone.AdvancedSetting.Enum', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.phone.AdvancedSetting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.phone.AdvancedSetting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.phone.AdvancedSetting.displayName = 'proto.phone.AdvancedSetting';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.phone.AdvancedSetting.prototype.toObject = function(opt_includeInstance) {
  return proto.phone.AdvancedSetting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.phone.AdvancedSetting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.phone.AdvancedSetting.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.phone.AdvancedSetting}
 */
proto.phone.AdvancedSetting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.phone.AdvancedSetting;
  return proto.phone.AdvancedSetting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.phone.AdvancedSetting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.phone.AdvancedSetting}
 */
proto.phone.AdvancedSetting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.phone.AdvancedSetting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.phone.AdvancedSetting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.phone.AdvancedSetting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.phone.AdvancedSetting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.phone.AdvancedSetting.Enum = {
  UNKNOWN: 0,
  EXTREME_OBSTACLE_MARGIN: 2,
  DEV_RECORDING_MODES: 5,
  COCKPIT_ANALOG_SLIDER_CONTROLS: 7,
  MICROHARD: 8,
  LAND_AFTER_LOST_CONN_RTH: 9,
  GIMBAL_INSPECTION_MODE: 11,
  DISTANCE_TO_CONTROLLER: 12,
  MAPBOX: 13,
  ALLOW_NIGHT_FLIGHT: 14,
  HERELINK: 15,
  IOS_RECORD_AUDIO: 16,
  HEIGHT_CEILING_TOGGLE: 17,
  DEV_CAPTURE_SETTINGS: 19,
  DISABLE_PHONE_LOG_CULL: 20,
  ENABLE_NEW_LOG_UPLOAD_FLOW: 21,
  BRIDGE_ESTIMATION_MODE: 25,
  RETURN_VISION_ONLY_TOGGLE: 26,
  SUPERZOOM: 28,
  DIGITAL_ZOOM: 34,
  MOBILE_DEV_MENU: 30,
  ENABLE_MISSIONS: 35,
  EXTENDED_WAYPOINT_FLIGHT: 31,
  SKYDIOLINK_AES256: 32,
  VEHICLE_TELEOPERATION: 33,
  OFFLINE: 36,
  C78_CUSTOMIZE_VEHICLE_WIFI: 37,
  RGB_LED_DISABLE: 38,
  MOBILE_RUNMODE_SWITCHING: 39,
  SHOW_MODEL_VIEWER: 40,
  UCON_ENABLED_WITH_SIM: 41,
  PINCH_CUSTOMIZATION: 42,
  DOUBLE_TAP_TOGGLE: 43,
  UCON_MANAGEMENT: 44,
  ALLOW_SDCARD_OVERWRITE: 45,
  MOBILE_STREAMING: 46,
  CRASH_TESTING_MENU: 49,
  KEYFRAME_ENABLED: 50,
  SHOW_LANGUAGE_PREFERENCE: 51,
  CONTROLLER_UI_NAV: 52,
  MOBILE_LIVE_CLOUD_LINK: 53,
  C78_OS_UPDATE_ENABLED: 54,
  MOBILE_MERGE_LOCAL_VIDEO_PREVIEW: 55,
  ENABLE_SCOUT: 56,
  MOBILE_STREAMING_DATA_SAVER: 57,
  DYNAMIC_OBSTACLE_AVOIDANCE_ENABLED: 58,
  RTSP_STREAMING: 59,
  TAK_CONFIG_ENABLED: 60,
  RID_FEATURE_ENABLED: 61,
  ALLOW_VESSEL_MODE_FLIGHT: 62,
  THERMAL_ROI_ENABLED: 63,
  RF_HIGH_POWER_ENABLED: 64,
  USE_BACKTRACKING_RTX_TOGGLE: 65,
  ALLOW_ON_VEHICLE_PHOTOGRAMMETRY: 66,
  RID_FEATURE_DISABLED: 67,
  ENABLE_GEOFENCES: 68,
  FULL_FRAME_PHOTO_ENABLED: 69,
  COCKPIT3_ENABLED: 70,
  RANGEFINDING: 71,
  ALLOW_NIGHT_AUTONOMY: 72,
  SITE_MAPPER: 73,
  ALLOW_RAPID_DESCENT: 74,
  ENABLE_USER_CAMERA_TAP_TO_FOCUS: 75,
  GATE3_ENABLED: 76,
  MOBILE_THEME_SWITCHING_ENABLED: 77,
  FLIGHT_SCREEN_RECORD_ENABLED: 79,
  DISABLE_MH_2_4BAND: 81,
  CROSSHAIR_COORDINATES_ENABLED: 82,
  MOBILE_CELLULAR_TELEOP: 83,
  DPAD_ACTION_WHEEL_ENABLED: 84,
  ALLOW_3DS_ON_CELLULAR: 85,
  ENABLE_SRR_UI: 86,
  ALLOW_MEDIA_ENCRYPTION: 87,
  ENABLE_M1_BAND: 88,
  ENABLE_PUBLIC_SAFETY_LIGHTS: 89,
  ENABLE_ATTITUDE_RTX: 90,
  ENABLE_MANUAL_WFF: 91,
  ENABLE_AR_OBSERVER_VIEW: 92,
  ENABLE_VEHICLE_GPS_TOGGLE: 93,
  ENABLE_ATTITUDE_TOGGLE: 94,
  ENABLE_C18D_ONLINE: 95,
  ENABLE_AXONRESPOND_UI: 96,
  ENABLE_MULTIVIEWERSTREAMING_UI: 97,
  ENABLE_MULTI_USER_CONTROLLER: 98,
  ENABLE_MAVLINK_UI: 99,
  ENABLE_LEGACY_LOG_SYNC_FLOW: 100,
  ENABLE_LINK_QUALITY_FEEDBACK: 101,
  ENABLE_C18_BACKHAUL: 102,
  EUROPEAN_UNION: 103,
  ALLOW_ES_LANGUAGE: 104,
  ALLOW_DE_LANGUAGE: 105,
  ALLOW_HI_LANGUAGE: 106,
  ALLOW_JA_LANGUAGE: 107,
  ALLOW_UK_LANGUAGE: 108,
  ALLOW_ZH_LANGUAGE: 109,
  ENABLE_FORCE_VIO_DEGRADED: 110,
  ENABLE_C18D_MICROHARD_OFFLINE_CONFIG: 111,
  DISABLE_LEGACY_ANALYTICS: 112,
  ENABLE_MOBILE_LOG_SYNC_DETAILS_MODAL_UI: 113,
  ENABLE_VEHICLE_LOG_EXPORT_TO_USBC: 114
};

goog.object.extend(exports, proto.phone);

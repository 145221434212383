// source: pbtypes/vehicle/infrastructure/flight_deck/media.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.flight_deck.Media', null, global);
goog.exportSymbol('proto.flight_deck.Media.AudioDetail', null, global);
goog.exportSymbol('proto.flight_deck.Media.AudioDetail.AudioKind', null, global);
goog.exportSymbol('proto.flight_deck.Media.GenericFileDetail', null, global);
goog.exportSymbol('proto.flight_deck.Media.GenericFileDetail.GenericFileKind', null, global);
goog.exportSymbol('proto.flight_deck.Media.HashType', null, global);
goog.exportSymbol('proto.flight_deck.Media.ImageDetail', null, global);
goog.exportSymbol('proto.flight_deck.Media.ImageDetail.ImageKind', null, global);
goog.exportSymbol('proto.flight_deck.Media.LogFileDetail', null, global);
goog.exportSymbol('proto.flight_deck.Media.LogFileDetail.LogFileKind', null, global);
goog.exportSymbol('proto.flight_deck.Media.MediaStatus', null, global);
goog.exportSymbol('proto.flight_deck.Media.OneofDetailCase', null, global);
goog.exportSymbol('proto.flight_deck.Media.VideoDetail', null, global);
goog.exportSymbol('proto.flight_deck.Media.VideoDetail.VideoKind', null, global);
goog.exportSymbol('proto.flight_deck.PromiseAudioRequest', null, global);
goog.exportSymbol('proto.flight_deck.PromiseAudioResponse', null, global);
goog.exportSymbol('proto.flight_deck.UnpromiseAudioRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flight_deck.PromiseAudioRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flight_deck.PromiseAudioRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flight_deck.PromiseAudioRequest.displayName = 'proto.flight_deck.PromiseAudioRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flight_deck.PromiseAudioResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flight_deck.PromiseAudioResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flight_deck.PromiseAudioResponse.displayName = 'proto.flight_deck.PromiseAudioResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flight_deck.UnpromiseAudioRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flight_deck.UnpromiseAudioRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flight_deck.UnpromiseAudioRequest.displayName = 'proto.flight_deck.UnpromiseAudioRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flight_deck.Media = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.flight_deck.Media.oneofGroups_);
};
goog.inherits(proto.flight_deck.Media, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flight_deck.Media.displayName = 'proto.flight_deck.Media';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flight_deck.Media.ImageDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flight_deck.Media.ImageDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flight_deck.Media.ImageDetail.displayName = 'proto.flight_deck.Media.ImageDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flight_deck.Media.VideoDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flight_deck.Media.VideoDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flight_deck.Media.VideoDetail.displayName = 'proto.flight_deck.Media.VideoDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flight_deck.Media.AudioDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flight_deck.Media.AudioDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flight_deck.Media.AudioDetail.displayName = 'proto.flight_deck.Media.AudioDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flight_deck.Media.LogFileDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flight_deck.Media.LogFileDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flight_deck.Media.LogFileDetail.displayName = 'proto.flight_deck.Media.LogFileDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flight_deck.Media.GenericFileDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flight_deck.Media.GenericFileDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flight_deck.Media.GenericFileDetail.displayName = 'proto.flight_deck.Media.GenericFileDetail';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flight_deck.PromiseAudioRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.flight_deck.PromiseAudioRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flight_deck.PromiseAudioRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.PromiseAudioRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
flightId: jspb.Message.getFieldWithDefault(msg, 1, ""),
startUtime: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flight_deck.PromiseAudioRequest}
 */
proto.flight_deck.PromiseAudioRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flight_deck.PromiseAudioRequest;
  return proto.flight_deck.PromiseAudioRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flight_deck.PromiseAudioRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flight_deck.PromiseAudioRequest}
 */
proto.flight_deck.PromiseAudioRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartUtime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flight_deck.PromiseAudioRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flight_deck.PromiseAudioRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flight_deck.PromiseAudioRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.PromiseAudioRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlightId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStartUtime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string flight_id = 1;
 * @return {string}
 */
proto.flight_deck.PromiseAudioRequest.prototype.getFlightId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.flight_deck.PromiseAudioRequest} returns this
 */
proto.flight_deck.PromiseAudioRequest.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 start_utime = 2;
 * @return {number}
 */
proto.flight_deck.PromiseAudioRequest.prototype.getStartUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.flight_deck.PromiseAudioRequest} returns this
 */
proto.flight_deck.PromiseAudioRequest.prototype.setStartUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flight_deck.PromiseAudioResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.flight_deck.PromiseAudioResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flight_deck.PromiseAudioResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.PromiseAudioResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
promiseId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flight_deck.PromiseAudioResponse}
 */
proto.flight_deck.PromiseAudioResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flight_deck.PromiseAudioResponse;
  return proto.flight_deck.PromiseAudioResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flight_deck.PromiseAudioResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flight_deck.PromiseAudioResponse}
 */
proto.flight_deck.PromiseAudioResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPromiseId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flight_deck.PromiseAudioResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flight_deck.PromiseAudioResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flight_deck.PromiseAudioResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.PromiseAudioResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPromiseId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string promise_id = 1;
 * @return {string}
 */
proto.flight_deck.PromiseAudioResponse.prototype.getPromiseId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.flight_deck.PromiseAudioResponse} returns this
 */
proto.flight_deck.PromiseAudioResponse.prototype.setPromiseId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flight_deck.UnpromiseAudioRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.flight_deck.UnpromiseAudioRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flight_deck.UnpromiseAudioRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.UnpromiseAudioRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
promiseId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flight_deck.UnpromiseAudioRequest}
 */
proto.flight_deck.UnpromiseAudioRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flight_deck.UnpromiseAudioRequest;
  return proto.flight_deck.UnpromiseAudioRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flight_deck.UnpromiseAudioRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flight_deck.UnpromiseAudioRequest}
 */
proto.flight_deck.UnpromiseAudioRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPromiseId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flight_deck.UnpromiseAudioRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flight_deck.UnpromiseAudioRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flight_deck.UnpromiseAudioRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.UnpromiseAudioRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPromiseId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string promise_id = 1;
 * @return {string}
 */
proto.flight_deck.UnpromiseAudioRequest.prototype.getPromiseId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.flight_deck.UnpromiseAudioRequest} returns this
 */
proto.flight_deck.UnpromiseAudioRequest.prototype.setPromiseId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.flight_deck.Media.oneofGroups_ = [[7,8,9,11,12]];

/**
 * @enum {number}
 */
proto.flight_deck.Media.OneofDetailCase = {
  ONEOF_DETAIL_NOT_SET: 0,
  IMAGE_DETAIL: 7,
  VIDEO_DETAIL: 8,
  AUDIO_DETAIL: 9,
  LOG_FILE_DETAIL: 11,
  GENERIC_FILE_DETAIL: 12
};

/**
 * @return {proto.flight_deck.Media.OneofDetailCase}
 */
proto.flight_deck.Media.prototype.getOneofDetailCase = function() {
  return /** @type {proto.flight_deck.Media.OneofDetailCase} */(jspb.Message.computeOneofCase(this, proto.flight_deck.Media.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flight_deck.Media.prototype.toObject = function(opt_includeInstance) {
  return proto.flight_deck.Media.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flight_deck.Media} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.Media.toObject = function(includeInstance, msg) {
  var f, obj = {
mediaId: jspb.Message.getFieldWithDefault(msg, 1, ""),
flightId: jspb.Message.getFieldWithDefault(msg, 2, ""),
scanId: jspb.Message.getFieldWithDefault(msg, 4, ""),
fileUri: jspb.Message.getFieldWithDefault(msg, 3, ""),
size: jspb.Message.getFieldWithDefault(msg, 5, 0),
mtime: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
kind: jspb.Message.getFieldWithDefault(msg, 13, ""),
status: jspb.Message.getFieldWithDefault(msg, 10, 0),
available: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
deliveredToCloud: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
hash: jspb.Message.getFieldWithDefault(msg, 16, ""),
hashType: jspb.Message.getFieldWithDefault(msg, 17, 0),
imageDetail: (f = msg.getImageDetail()) && proto.flight_deck.Media.ImageDetail.toObject(includeInstance, f),
videoDetail: (f = msg.getVideoDetail()) && proto.flight_deck.Media.VideoDetail.toObject(includeInstance, f),
audioDetail: (f = msg.getAudioDetail()) && proto.flight_deck.Media.AudioDetail.toObject(includeInstance, f),
logFileDetail: (f = msg.getLogFileDetail()) && proto.flight_deck.Media.LogFileDetail.toObject(includeInstance, f),
genericFileDetail: (f = msg.getGenericFileDetail()) && proto.flight_deck.Media.GenericFileDetail.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flight_deck.Media}
 */
proto.flight_deck.Media.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flight_deck.Media;
  return proto.flight_deck.Media.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flight_deck.Media} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flight_deck.Media}
 */
proto.flight_deck.Media.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMediaId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setScanId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileUri(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSize(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMtime(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setKind(value);
      break;
    case 10:
      var value = /** @type {!proto.flight_deck.Media.MediaStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAvailable(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeliveredToCloud(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setHash(value);
      break;
    case 17:
      var value = /** @type {!proto.flight_deck.Media.HashType} */ (reader.readEnum());
      msg.setHashType(value);
      break;
    case 7:
      var value = new proto.flight_deck.Media.ImageDetail;
      reader.readMessage(value,proto.flight_deck.Media.ImageDetail.deserializeBinaryFromReader);
      msg.setImageDetail(value);
      break;
    case 8:
      var value = new proto.flight_deck.Media.VideoDetail;
      reader.readMessage(value,proto.flight_deck.Media.VideoDetail.deserializeBinaryFromReader);
      msg.setVideoDetail(value);
      break;
    case 9:
      var value = new proto.flight_deck.Media.AudioDetail;
      reader.readMessage(value,proto.flight_deck.Media.AudioDetail.deserializeBinaryFromReader);
      msg.setAudioDetail(value);
      break;
    case 11:
      var value = new proto.flight_deck.Media.LogFileDetail;
      reader.readMessage(value,proto.flight_deck.Media.LogFileDetail.deserializeBinaryFromReader);
      msg.setLogFileDetail(value);
      break;
    case 12:
      var value = new proto.flight_deck.Media.GenericFileDetail;
      reader.readMessage(value,proto.flight_deck.Media.GenericFileDetail.deserializeBinaryFromReader);
      msg.setGenericFileDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flight_deck.Media.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flight_deck.Media.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flight_deck.Media} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.Media.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMediaId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFlightId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getScanId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFileUri();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSize();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getMtime();
  if (f !== 0.0) {
    writer.writeDouble(
      14,
      f
    );
  }
  f = message.getKind();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getAvailable();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getDeliveredToCloud();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getHash();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getHashType();
  if (f !== 0.0) {
    writer.writeEnum(
      17,
      f
    );
  }
  f = message.getImageDetail();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.flight_deck.Media.ImageDetail.serializeBinaryToWriter
    );
  }
  f = message.getVideoDetail();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.flight_deck.Media.VideoDetail.serializeBinaryToWriter
    );
  }
  f = message.getAudioDetail();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.flight_deck.Media.AudioDetail.serializeBinaryToWriter
    );
  }
  f = message.getLogFileDetail();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.flight_deck.Media.LogFileDetail.serializeBinaryToWriter
    );
  }
  f = message.getGenericFileDetail();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.flight_deck.Media.GenericFileDetail.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.flight_deck.Media.HashType = {
  NONE: 0,
  SHA1: 1
};

/**
 * @enum {number}
 */
proto.flight_deck.Media.MediaStatus = {
  UNKNOWN: 0,
  PENDING: 1,
  DELIVERED: 2,
  REMOVED: 3,
  IMPOSSIBLE: 4
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flight_deck.Media.ImageDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.flight_deck.Media.ImageDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flight_deck.Media.ImageDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.Media.ImageDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
kind: jspb.Message.getFieldWithDefault(msg, 5, 0),
sequenceId: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flight_deck.Media.ImageDetail}
 */
proto.flight_deck.Media.ImageDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flight_deck.Media.ImageDetail;
  return proto.flight_deck.Media.ImageDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flight_deck.Media.ImageDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flight_deck.Media.ImageDetail}
 */
proto.flight_deck.Media.ImageDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 5:
      var value = /** @type {!proto.flight_deck.Media.ImageDetail.ImageKind} */ (reader.readEnum());
      msg.setKind(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSequenceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flight_deck.Media.ImageDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flight_deck.Media.ImageDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flight_deck.Media.ImageDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.Media.ImageDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getKind();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getSequenceId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.flight_deck.Media.ImageDetail.ImageKind = {
  NO_KIND: 0,
  THUMBNAIL: 1,
  PHOTO: 3,
  PANORAMA_VERTICAL: 4,
  PANORAMA_HORIZONTAL: 5,
  PANORAMA_360: 6
};

/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.flight_deck.Media.ImageDetail.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.flight_deck.Media.ImageDetail} returns this
 */
proto.flight_deck.Media.ImageDetail.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional ImageKind kind = 5;
 * @return {!proto.flight_deck.Media.ImageDetail.ImageKind}
 */
proto.flight_deck.Media.ImageDetail.prototype.getKind = function() {
  return /** @type {!proto.flight_deck.Media.ImageDetail.ImageKind} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.flight_deck.Media.ImageDetail.ImageKind} value
 * @return {!proto.flight_deck.Media.ImageDetail} returns this
 */
proto.flight_deck.Media.ImageDetail.prototype.setKind = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string sequence_id = 6;
 * @return {string}
 */
proto.flight_deck.Media.ImageDetail.prototype.getSequenceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.flight_deck.Media.ImageDetail} returns this
 */
proto.flight_deck.Media.ImageDetail.prototype.setSequenceId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flight_deck.Media.VideoDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.flight_deck.Media.VideoDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flight_deck.Media.VideoDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.Media.VideoDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
startUtime: jspb.Message.getFieldWithDefault(msg, 1, 0),
durationUsec: jspb.Message.getFieldWithDefault(msg, 2, 0),
kind: jspb.Message.getFieldWithDefault(msg, 5, 0),
format: jspb.Message.getFieldWithDefault(msg, 6, ""),
width: jspb.Message.getFieldWithDefault(msg, 7, 0),
height: jspb.Message.getFieldWithDefault(msg, 8, 0),
fps: jspb.Message.getFieldWithDefault(msg, 9, 0),
bitrate: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flight_deck.Media.VideoDetail}
 */
proto.flight_deck.Media.VideoDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flight_deck.Media.VideoDetail;
  return proto.flight_deck.Media.VideoDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flight_deck.Media.VideoDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flight_deck.Media.VideoDetail}
 */
proto.flight_deck.Media.VideoDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartUtime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDurationUsec(value);
      break;
    case 5:
      var value = /** @type {!proto.flight_deck.Media.VideoDetail.VideoKind} */ (reader.readEnum());
      msg.setKind(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormat(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWidth(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHeight(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFps(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBitrate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flight_deck.Media.VideoDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flight_deck.Media.VideoDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flight_deck.Media.VideoDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.Media.VideoDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDurationUsec();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getKind();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getFormat();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getWidth();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getHeight();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getFps();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getBitrate();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.flight_deck.Media.VideoDetail.VideoKind = {
  NO_KIND: 0,
  RAW: 1,
  MASTER: 2,
  PREVIEW: 4
};

/**
 * optional int64 start_utime = 1;
 * @return {number}
 */
proto.flight_deck.Media.VideoDetail.prototype.getStartUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.flight_deck.Media.VideoDetail} returns this
 */
proto.flight_deck.Media.VideoDetail.prototype.setStartUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 duration_usec = 2;
 * @return {number}
 */
proto.flight_deck.Media.VideoDetail.prototype.getDurationUsec = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.flight_deck.Media.VideoDetail} returns this
 */
proto.flight_deck.Media.VideoDetail.prototype.setDurationUsec = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional VideoKind kind = 5;
 * @return {!proto.flight_deck.Media.VideoDetail.VideoKind}
 */
proto.flight_deck.Media.VideoDetail.prototype.getKind = function() {
  return /** @type {!proto.flight_deck.Media.VideoDetail.VideoKind} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.flight_deck.Media.VideoDetail.VideoKind} value
 * @return {!proto.flight_deck.Media.VideoDetail} returns this
 */
proto.flight_deck.Media.VideoDetail.prototype.setKind = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string format = 6;
 * @return {string}
 */
proto.flight_deck.Media.VideoDetail.prototype.getFormat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.flight_deck.Media.VideoDetail} returns this
 */
proto.flight_deck.Media.VideoDetail.prototype.setFormat = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 width = 7;
 * @return {number}
 */
proto.flight_deck.Media.VideoDetail.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.flight_deck.Media.VideoDetail} returns this
 */
proto.flight_deck.Media.VideoDetail.prototype.setWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 height = 8;
 * @return {number}
 */
proto.flight_deck.Media.VideoDetail.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.flight_deck.Media.VideoDetail} returns this
 */
proto.flight_deck.Media.VideoDetail.prototype.setHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 fps = 9;
 * @return {number}
 */
proto.flight_deck.Media.VideoDetail.prototype.getFps = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.flight_deck.Media.VideoDetail} returns this
 */
proto.flight_deck.Media.VideoDetail.prototype.setFps = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 bitrate = 10;
 * @return {number}
 */
proto.flight_deck.Media.VideoDetail.prototype.getBitrate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.flight_deck.Media.VideoDetail} returns this
 */
proto.flight_deck.Media.VideoDetail.prototype.setBitrate = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flight_deck.Media.AudioDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.flight_deck.Media.AudioDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flight_deck.Media.AudioDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.Media.AudioDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
startUtime: jspb.Message.getFieldWithDefault(msg, 1, 0),
clientId: jspb.Message.getFieldWithDefault(msg, 3, ""),
durationUsec: jspb.Message.getFieldWithDefault(msg, 4, 0),
kind: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flight_deck.Media.AudioDetail}
 */
proto.flight_deck.Media.AudioDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flight_deck.Media.AudioDetail;
  return proto.flight_deck.Media.AudioDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flight_deck.Media.AudioDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flight_deck.Media.AudioDetail}
 */
proto.flight_deck.Media.AudioDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartUtime(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDurationUsec(value);
      break;
    case 5:
      var value = /** @type {!proto.flight_deck.Media.AudioDetail.AudioKind} */ (reader.readEnum());
      msg.setKind(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flight_deck.Media.AudioDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flight_deck.Media.AudioDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flight_deck.Media.AudioDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.Media.AudioDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDurationUsec();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getKind();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.flight_deck.Media.AudioDetail.AudioKind = {
  NO_KIND: 0,
  REMOTE: 1,
  ALIGNED: 2
};

/**
 * optional int64 start_utime = 1;
 * @return {number}
 */
proto.flight_deck.Media.AudioDetail.prototype.getStartUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.flight_deck.Media.AudioDetail} returns this
 */
proto.flight_deck.Media.AudioDetail.prototype.setStartUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string client_id = 3;
 * @return {string}
 */
proto.flight_deck.Media.AudioDetail.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.flight_deck.Media.AudioDetail} returns this
 */
proto.flight_deck.Media.AudioDetail.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 duration_usec = 4;
 * @return {number}
 */
proto.flight_deck.Media.AudioDetail.prototype.getDurationUsec = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.flight_deck.Media.AudioDetail} returns this
 */
proto.flight_deck.Media.AudioDetail.prototype.setDurationUsec = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional AudioKind kind = 5;
 * @return {!proto.flight_deck.Media.AudioDetail.AudioKind}
 */
proto.flight_deck.Media.AudioDetail.prototype.getKind = function() {
  return /** @type {!proto.flight_deck.Media.AudioDetail.AudioKind} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.flight_deck.Media.AudioDetail.AudioKind} value
 * @return {!proto.flight_deck.Media.AudioDetail} returns this
 */
proto.flight_deck.Media.AudioDetail.prototype.setKind = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flight_deck.Media.LogFileDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.flight_deck.Media.LogFileDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flight_deck.Media.LogFileDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.Media.LogFileDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
kind: jspb.Message.getFieldWithDefault(msg, 1, 0),
associatedMediaId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flight_deck.Media.LogFileDetail}
 */
proto.flight_deck.Media.LogFileDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flight_deck.Media.LogFileDetail;
  return proto.flight_deck.Media.LogFileDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flight_deck.Media.LogFileDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flight_deck.Media.LogFileDetail}
 */
proto.flight_deck.Media.LogFileDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.flight_deck.Media.LogFileDetail.LogFileKind} */ (reader.readEnum());
      msg.setKind(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssociatedMediaId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flight_deck.Media.LogFileDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flight_deck.Media.LogFileDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flight_deck.Media.LogFileDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.Media.LogFileDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKind();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getAssociatedMediaId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.flight_deck.Media.LogFileDetail.LogFileKind = {
  NO_KIND: 0,
  FLIGHT_LOG: 3,
  FLIGHT_IMAGE_LOG: 4,
  NAV_CAM_IMAGES: 7,
  PROCESS_LOGS: 8,
  TELEMETRY_LOG: 9,
  LOCALIZATION_NAV_CAM_POSE_GRAPH: 10,
  QCU_FLIGHT_LOG: 11,
  DOCK_FLIGHT_LOG: 12,
  C93_RUNLOG: 13,
  C18_RUNLOG: 14
};

/**
 * optional LogFileKind kind = 1;
 * @return {!proto.flight_deck.Media.LogFileDetail.LogFileKind}
 */
proto.flight_deck.Media.LogFileDetail.prototype.getKind = function() {
  return /** @type {!proto.flight_deck.Media.LogFileDetail.LogFileKind} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.flight_deck.Media.LogFileDetail.LogFileKind} value
 * @return {!proto.flight_deck.Media.LogFileDetail} returns this
 */
proto.flight_deck.Media.LogFileDetail.prototype.setKind = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string associated_media_id = 2;
 * @return {string}
 */
proto.flight_deck.Media.LogFileDetail.prototype.getAssociatedMediaId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.flight_deck.Media.LogFileDetail} returns this
 */
proto.flight_deck.Media.LogFileDetail.prototype.setAssociatedMediaId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flight_deck.Media.GenericFileDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.flight_deck.Media.GenericFileDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flight_deck.Media.GenericFileDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.Media.GenericFileDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
kind: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flight_deck.Media.GenericFileDetail}
 */
proto.flight_deck.Media.GenericFileDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flight_deck.Media.GenericFileDetail;
  return proto.flight_deck.Media.GenericFileDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flight_deck.Media.GenericFileDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flight_deck.Media.GenericFileDetail}
 */
proto.flight_deck.Media.GenericFileDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.flight_deck.Media.GenericFileDetail.GenericFileKind} */ (reader.readEnum());
      msg.setKind(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flight_deck.Media.GenericFileDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flight_deck.Media.GenericFileDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flight_deck.Media.GenericFileDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.Media.GenericFileDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKind();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.flight_deck.Media.GenericFileDetail.GenericFileKind = {
  NO_KIND: 0
};

/**
 * optional GenericFileKind kind = 1;
 * @return {!proto.flight_deck.Media.GenericFileDetail.GenericFileKind}
 */
proto.flight_deck.Media.GenericFileDetail.prototype.getKind = function() {
  return /** @type {!proto.flight_deck.Media.GenericFileDetail.GenericFileKind} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.flight_deck.Media.GenericFileDetail.GenericFileKind} value
 * @return {!proto.flight_deck.Media.GenericFileDetail} returns this
 */
proto.flight_deck.Media.GenericFileDetail.prototype.setKind = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string media_id = 1;
 * @return {string}
 */
proto.flight_deck.Media.prototype.getMediaId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.flight_deck.Media} returns this
 */
proto.flight_deck.Media.prototype.setMediaId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string flight_id = 2;
 * @return {string}
 */
proto.flight_deck.Media.prototype.getFlightId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.flight_deck.Media} returns this
 */
proto.flight_deck.Media.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string scan_id = 4;
 * @return {string}
 */
proto.flight_deck.Media.prototype.getScanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.flight_deck.Media} returns this
 */
proto.flight_deck.Media.prototype.setScanId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string file_uri = 3;
 * @return {string}
 */
proto.flight_deck.Media.prototype.getFileUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.flight_deck.Media} returns this
 */
proto.flight_deck.Media.prototype.setFileUri = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 size = 5;
 * @return {number}
 */
proto.flight_deck.Media.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.flight_deck.Media} returns this
 */
proto.flight_deck.Media.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional double mtime = 14;
 * @return {number}
 */
proto.flight_deck.Media.prototype.getMtime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.flight_deck.Media} returns this
 */
proto.flight_deck.Media.prototype.setMtime = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional string kind = 13;
 * @return {string}
 */
proto.flight_deck.Media.prototype.getKind = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.flight_deck.Media} returns this
 */
proto.flight_deck.Media.prototype.setKind = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional MediaStatus status = 10;
 * @return {!proto.flight_deck.Media.MediaStatus}
 */
proto.flight_deck.Media.prototype.getStatus = function() {
  return /** @type {!proto.flight_deck.Media.MediaStatus} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.flight_deck.Media.MediaStatus} value
 * @return {!proto.flight_deck.Media} returns this
 */
proto.flight_deck.Media.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional bool available = 6;
 * @return {boolean}
 */
proto.flight_deck.Media.prototype.getAvailable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.Media} returns this
 */
proto.flight_deck.Media.prototype.setAvailable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool delivered_to_cloud = 15;
 * @return {boolean}
 */
proto.flight_deck.Media.prototype.getDeliveredToCloud = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.flight_deck.Media} returns this
 */
proto.flight_deck.Media.prototype.setDeliveredToCloud = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional string hash = 16;
 * @return {string}
 */
proto.flight_deck.Media.prototype.getHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.flight_deck.Media} returns this
 */
proto.flight_deck.Media.prototype.setHash = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional HashType hash_type = 17;
 * @return {!proto.flight_deck.Media.HashType}
 */
proto.flight_deck.Media.prototype.getHashType = function() {
  return /** @type {!proto.flight_deck.Media.HashType} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {!proto.flight_deck.Media.HashType} value
 * @return {!proto.flight_deck.Media} returns this
 */
proto.flight_deck.Media.prototype.setHashType = function(value) {
  return jspb.Message.setProto3EnumField(this, 17, value);
};


/**
 * optional ImageDetail image_detail = 7;
 * @return {?proto.flight_deck.Media.ImageDetail}
 */
proto.flight_deck.Media.prototype.getImageDetail = function() {
  return /** @type{?proto.flight_deck.Media.ImageDetail} */ (
    jspb.Message.getWrapperField(this, proto.flight_deck.Media.ImageDetail, 7));
};


/**
 * @param {?proto.flight_deck.Media.ImageDetail|undefined} value
 * @return {!proto.flight_deck.Media} returns this
*/
proto.flight_deck.Media.prototype.setImageDetail = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.flight_deck.Media.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flight_deck.Media} returns this
 */
proto.flight_deck.Media.prototype.clearImageDetail = function() {
  return this.setImageDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flight_deck.Media.prototype.hasImageDetail = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional VideoDetail video_detail = 8;
 * @return {?proto.flight_deck.Media.VideoDetail}
 */
proto.flight_deck.Media.prototype.getVideoDetail = function() {
  return /** @type{?proto.flight_deck.Media.VideoDetail} */ (
    jspb.Message.getWrapperField(this, proto.flight_deck.Media.VideoDetail, 8));
};


/**
 * @param {?proto.flight_deck.Media.VideoDetail|undefined} value
 * @return {!proto.flight_deck.Media} returns this
*/
proto.flight_deck.Media.prototype.setVideoDetail = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.flight_deck.Media.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flight_deck.Media} returns this
 */
proto.flight_deck.Media.prototype.clearVideoDetail = function() {
  return this.setVideoDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flight_deck.Media.prototype.hasVideoDetail = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional AudioDetail audio_detail = 9;
 * @return {?proto.flight_deck.Media.AudioDetail}
 */
proto.flight_deck.Media.prototype.getAudioDetail = function() {
  return /** @type{?proto.flight_deck.Media.AudioDetail} */ (
    jspb.Message.getWrapperField(this, proto.flight_deck.Media.AudioDetail, 9));
};


/**
 * @param {?proto.flight_deck.Media.AudioDetail|undefined} value
 * @return {!proto.flight_deck.Media} returns this
*/
proto.flight_deck.Media.prototype.setAudioDetail = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.flight_deck.Media.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flight_deck.Media} returns this
 */
proto.flight_deck.Media.prototype.clearAudioDetail = function() {
  return this.setAudioDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flight_deck.Media.prototype.hasAudioDetail = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional LogFileDetail log_file_detail = 11;
 * @return {?proto.flight_deck.Media.LogFileDetail}
 */
proto.flight_deck.Media.prototype.getLogFileDetail = function() {
  return /** @type{?proto.flight_deck.Media.LogFileDetail} */ (
    jspb.Message.getWrapperField(this, proto.flight_deck.Media.LogFileDetail, 11));
};


/**
 * @param {?proto.flight_deck.Media.LogFileDetail|undefined} value
 * @return {!proto.flight_deck.Media} returns this
*/
proto.flight_deck.Media.prototype.setLogFileDetail = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.flight_deck.Media.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flight_deck.Media} returns this
 */
proto.flight_deck.Media.prototype.clearLogFileDetail = function() {
  return this.setLogFileDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flight_deck.Media.prototype.hasLogFileDetail = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional GenericFileDetail generic_file_detail = 12;
 * @return {?proto.flight_deck.Media.GenericFileDetail}
 */
proto.flight_deck.Media.prototype.getGenericFileDetail = function() {
  return /** @type{?proto.flight_deck.Media.GenericFileDetail} */ (
    jspb.Message.getWrapperField(this, proto.flight_deck.Media.GenericFileDetail, 12));
};


/**
 * @param {?proto.flight_deck.Media.GenericFileDetail|undefined} value
 * @return {!proto.flight_deck.Media} returns this
*/
proto.flight_deck.Media.prototype.setGenericFileDetail = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.flight_deck.Media.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flight_deck.Media} returns this
 */
proto.flight_deck.Media.prototype.clearGenericFileDetail = function() {
  return this.setGenericFileDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flight_deck.Media.prototype.hasGenericFileDetail = function() {
  return jspb.Message.getField(this, 12) != null;
};


goog.object.extend(exports, proto.flight_deck);
